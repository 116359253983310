#about {
  background-color: #212121 !important;
  color: $white !important;
  @include heading-font;
  padding-top: 0;
}

.text-white {
  color: $white;
}

.text-yellow {
  color: $yellow;
}

.text-blue {
  color: $deepblue !important;
}

.vl {
  border-left: 6px solid green;
  height: 500px;
}

.img-profile {
  max-width: 10rem;
  max-height: 10rem;
  border: 0.2rem solid rgba(0, 0, 0, 0.5);
}

.portfolio__description {
  a {
    text-decoration: underline;
  }
}

.resume-content {
  a {
    color: $deepblue;
    cursor: pointer;
  }
  p {
    letter-spacing: 0.03em;
  }
  ul {
    list-style-type: square;
    padding-left: 16px;
  }
  li {
    margin-top: 1em;
    font-size: 0.7rem;
    line-height: 1.25;
  }
  li span {
    font-size: 1rem;
  }
  .subheading {
    @media (min-width: 992px) {
      margin-bottom: 1rem;
    }
  }
}

.resume-date {
  color: $secondary;
}

.card {
  width: 18rem;
  .btn-primary {
    background-color: $deepblue;
    border: none;
  }
  div.card-bottom {
    height: 3rem;
    a.btn {
      position: absolute;
      bottom: 1.25rem;
      right: 1.25rem;
    }
  }
}
