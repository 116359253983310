@import './scss/variables.scss';
@import './scss/mixins.scss';
@import './scss/global.scss';
@import './scss/nav.scss';
@import './scss/resume-item.scss';
@import './scss/bootstrap-overrides.scss';
@import './scss/bootstrap-overrides.scss';
@import './scss/resume.scss';

html {
  scroll-behavior: smooth;
}

body {
  padding-top: 0;
}

.navbar {
  @media (max-width: 992px) {
    display: none !important;
    height: 0;
  }
}

#about {
  h1,
  h3 {
    font-weight: 400;
  }
}

.portfolio {
  &__technology {
    font-size: 0.85rem;
    font-weight: 700;
  }

  a.image {
    width: 50%;
    text-align: center;
    img {
      max-width: 100%;
      margin: auto;
    }
    margin-right: 1em;
    @media (max-width: 576px) {
      width: 100%;
      margin: 0;
    }
  }
}

.blinking-cursor {
  font-weight: 100;
  font-size: 30px;
  color: #2e3d48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}

@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
}
