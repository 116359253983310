// Mixins
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400|Roboto+Mono:400,700&display=swap');

@mixin body-font {
  font-family: 'Open Sans', sans-serif;
  //  font-family: 'Muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
@mixin heading-font {
  font-family: 'Roboto Mono', sans-serif;
  //  font-family: 'Saira Extra Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
