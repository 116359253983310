#sideNav {
  .navbar-nav .nav-item .nav-link {
    font-weight: 800;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
  }
  .navbar-toggler {
    &:focus {
      outline-color: lighten($primary, 15%);
    }
  }
}
@media (min-width: 992px) {
  #sideNav{
    background-color: $primary !important;
  }
}


@media (min-width: 992px) {
  #sideNav {
    background-color: $white !important;
    border-right: 1px solid rgba(0,0,0,.1);
    //background-color: $primary !important;
    @include heading-font;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    width: $sidebar-base-width;
    height: 100vh;
    .navbar-brand {
      display: flex;

      margin: auto auto 0;
      padding: 0.5rem;
//       .img-profile {
//         max-width: 10rem;
//         max-height: 10rem;
//         //border: 0.5rem solid fade-out($white, 0.8);
// //        border: 0.3rem solid fade-out($white, 0.8);

//         border: 0.3rem solid $pink;
//       }
    }
    .navbar-collapse {
      display: flex;
      align-items: flex-start;
      flex-grow: 0;

      width: 100%;
      margin-bottom: auto;
      .navbar-nav {
        flex-direction: column;

        width: 100%;
        .nav-item {
          display: block;
          .nav-link {
            display: block;
            //color: $primary;
          }
          a{
            font-weight: 300 !important;
          }
          .active {
            //color: $pink !important;
            font-weight: bolder !important;
          }
        }
      }
    }
  }
}
